<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="!(isHandset$ | async)">
    <mat-toolbar class="logoDiv mat-toolbar-trans">
      <button mat-button>
        <span (click)="logout()" class="nav-login-title"> {{name}} </span><i class="fas fa-sign-out-alt"></i>
      </button>

<!--       <span>
        <img src="../../../assets/images/logo_tareas.png" alt="Tareas" width="175px">
      </span> -->
    </mat-toolbar>
    <div class="logo">
        <img src="../../../assets/images/logo_tareas.png" alt="Tareas" width="175px">
        <h2>tareas</h2>
    </div>

  <mat-nav-list id="mainNav">
      <!-- <a mat-list-item routerLink="/home" routerLinkActive="active"><i class="fas fa-home"></i>&nbsp;  Dashboard</a> -->
      <a mat-list-item routerLink="/leden" routerLinkActive="active"><i class="fas fa-users"></i>&nbsp; Leden</a>
      <a mat-list-item routerLink="/taken" routerLinkActive="active"><i class="fas fa-clipboard-list"></i>&nbsp; Taken</a>
      <a mat-list-item routerLink="/teams" routerLinkActive="active"><i class="fas fa-users"></i>&nbsp; Teams</a>
      <a mat-list-item routerLink="/planning" routerLinkActive="active"><i class="far fa-calendar-alt"></i>&nbsp; Planning</a>
      <!-- <mat-divider></mat-divider>
      <a mat-list-item routerLink="/beheer" routerLinkActive="active"><i class="fas fa-wrench"></i>&nbsp; beheer</a> -->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar id="mainNavBar" color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
            <span class="fill-remaining-space"></span>
            <!-- <small class="margin_right">{{loggedInUser}}</small> -->
            <a class="whitelink" mat-list-item (click)="logout()">
                <mat-icon matTooltip="Log out" aria-label="icon-button">exit_to_app</mat-icon>
            </a>
        </mat-toolbar>
        <ng-content>

        </ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>
<style type="text/css">
  .logoDiv {
    display: block;
    text-align: right;
    color: #fff;
    width: 100%;
    font-size: 14px;
    padding: 15px 15px 0px 0px;
    font-weight: normal;
}
.logoDiv span {
    padding: 0px 5px;
}
</style>
