import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar } from '@fullcalendar/core';
import nlLocale from '@fullcalendar/core/locales/nl';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { concatMap, switchMap, take, takeUntil } from 'rxjs/operators';
import { Activity } from 'src/app/models/activity/activity.model';
import { Certificate } from 'src/app/models/leden/certification.model';
import { Member } from 'src/app/models/leden/member.model';
import { Task } from 'src/app/models/taken/task.model';
import { ActivityService } from 'src/app/service/activity.service';
import { CertificateService } from 'src/app/service/Leden/certificate.service';
import { LedenService } from 'src/app/service/Leden/leden.service';
import { TakenService } from 'src/app/service/taken.service';
@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.css']
})

export class PlanningComponent implements OnInit, OnDestroy {
  calendarEvents: {id: string, title: string, date: string}[] = [] ;
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick : this.viewEventInfo.bind(this),
    eventClick : this.eventClick.bind(this),
    locale: nlLocale
  };
  viewEvent = false;
  activities: Array<Activity>;
  type: string;
  @ViewChild('fullcalendar') calendarComponent: FullCalendarComponent;

  calendarEl = document.getElementById('calendar');
  planningForm: FormGroup;
  tasks: Array<Task> = [];
  certificates: Array<Certificate> = [];
  selectedTaskId: string;
  selectedActivity: Activity;
  members: Member[];
  calendar = new Calendar(this.calendarEl, {});
 /** list of Members */
  protected membersList: Member[];

 /** control for the selected Member */
  public memberCtrl: FormControl = new FormControl();

 /** control for the selected members for invitation */
  public inviteMemberMultiCtrl: FormControl = new FormControl();
 /** control for the MatSelect filter keyword */
  public memberFilterCtrl: FormControl = new FormControl('');
 /** list of Members filtered by search keyword */
  public filteredMembers: ReplaySubject<Member[]> = new ReplaySubject<Member[]>(1);

 // @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  private singleSelect: MatSelect;
  @ViewChild('singleSelect') set content(content: MatSelect) {
     if (content) { // initially setter gets called with undefined
          this.singleSelect = content;
          this.setInitialValue();
    }
  }
  // @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  private multiSelect: MatSelect;
  @ViewChild('singleSelect') set Secondcontent(content: MatSelect) {
     if (content) { // initially setter gets called with undefined
          this.multiSelect = content;
          this.setInitialValue();
    }
  }
 /** Subject that emits when the component has been destroyed. */
  protected onDestroy = new Subject<void>();
  constructor(private activityService: ActivityService, private datePipe: DatePipe,
              private formBuilder: FormBuilder,
              private taskService: TakenService,
              private cerfService: CertificateService,
              private dialog: MatDialog,
              private memberService: LedenService, private changeDetector: ChangeDetectorRef) {
                this.getMembers();
              }

  ngOnInit() {
    this.getActivities();
    this.getCertificates();
    this.getTasks();
    // listen for search field value changes
    this.memberFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterMembers();
      });
  }

  getActivities() {
    this.activityService.getActivities().subscribe((resp: Activity[]) => {
      if (resp) {
        this.activities = [];
        this.calendarEvents = [];
        resp.map(data => {
          this.activities.push(data);
          // console.log('data: ', JSON.stringify(data, null, 2));
          this.calendarEvents.push({
            id : data.id,
            title : data.task.name,
            date : this.datePipe.transform(data.time, 'yyyy-MM-dd')
          });
        });
        this.calendarOptions.events = this.calendarEvents;
      }
  });
  }

  createPlanningForm() {
    this.planningForm =  this.formBuilder.group({
      id: new FormControl(),
      taskId : new FormControl(),
      certificateRequirement : new FormControl(),
      description : new FormControl(),
      time : new FormControl(),
    });
  }

  getTasks() {
    this.taskService.getData().subscribe((resp: Task[]) => {
      if (resp) {
        this.tasks = resp;
        this.selectedTaskId = resp[0].id;
      }
    });
  }

  getCertificates() {
    this.cerfService.findAll().subscribe((resp: Certificate[]) => {
      if (resp) {
        this.certificates = resp;
        console.log('this.certificates: ', this.certificates);
      }
    });
  }

  onSubmit(): void {
    const calendarApi = this.calendarComponent.getApi();
    const memberId = this.memberCtrl.value.id;

    if (this.type === 'Edit') {
      this.activityService.updateActivity(this.planningForm.value.id, this.planningForm.value)
      .pipe(
        // tap(res => console.log('First Result', res)),
        switchMap((resp: Activity) => {
            console.log('resp: ', resp);
            this.viewEvent = false;
            if (undefined !== memberId) {
              return this.activityService.assignToMember(resp, memberId);
            } else {
              return new Observable((observer) => {
                observer.next(resp);
                observer.complete();
              });
            }
        }),
      )
      .subscribe((resp: Activity) => {
        if (resp) {
          const event = {
            id: resp.id,
            title: resp.task.name,
            date: this.datePipe.transform(resp.time, 'yyyy-MM-dd')
          };
          Object.assign(this.calendarEvents.find(x => x.id === resp.id), event);
          Object.assign(this.activities.find(x => x.id === resp.id), resp);
          this.getActivities();
          calendarApi.destroy();
          calendarApi.render();
          this.viewEvent = false;
        }
      }, (error: any) => {console.log(error); this.viewEvent = false; });
    } else {
      console.log('this.planningForm.value: ', this.planningForm.value);
      this.activityService.addActivity(this.planningForm.value)
      .pipe(
        concatMap((resp: Activity) => {
          if (undefined !== memberId) {
            return this.activityService.assignToMember(resp, memberId);
          } else {
            return new Observable((observer) => {
              observer.next(resp);
              observer.complete();
          });
          }
        })
      ).subscribe((resp: Activity) => {
        if (resp) {
          this.activities.push(resp);
          this.calendarEvents = this.calendarEvents.concat({
            id: resp.id,
            title: resp.task.name,
            date: this.datePipe.transform(resp.time, 'yyyy-MM-dd')
          });
          this.getActivities();
          this.viewEvent = false;
        }
      }, (error: any) => {console.log(error); this.viewEvent = false; });
    }

  }
  /**
   * @param event : Calendar event we will fetch selected date from event
   */
 viewEventInfo(event) {
   const eventDate = this.datePipe.transform(event.date, 'yyyy-MM-dd');
   const currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
   if (eventDate >= currentDate) {
    this.type = 'Add';
    this.createPlanningForm();
    this.planningForm.controls.time.setValue(eventDate);
    this.memberCtrl.setValue('');
    this.viewEvent = true;
   }
  }

  /**
   * @param model : Event Object
   */
  eventClick(model) {
    this.changeDetector.detectChanges();
    const activityId = model.event._def.publicId;
    const activity = this.activities.filter(x => x.id === activityId);

    if (activity.length > 0) {
      this.selectedActivity = activity[0];
      this.type = 'Edit';
      this.createPlanningForm();
      this.planningForm.patchValue(activity[0]);
      this.planningForm.controls['taskId'].setValue(activity[0].task.id);
      this.selectedTaskId = activity[0].task.id;
      if (activity[0].isAssigned) {
        this.memberCtrl.setValue(activity[0].isAssigned);
        this.filteredMembers.next([...this.members]);
      } else {
        this.memberCtrl.setValue('');
      }
      this.viewEvent = true;
    }
  }

  cancelActivity(activityId: string) {
    this.activityService.cancelActivity(activityId).subscribe((resp => {
      this.getActivities();
    }));

    this.viewEvent = false;
    }

    addPlanning() {
      this.type = 'Add';
      this.createPlanningForm();
      this.planningForm.controls.time.setValue(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      this.viewEvent = true;
    }

    inviteMembers() {
      const Invitations = {
        activityId : this.planningForm.value.id,
        message : 'string',
        memberIds : this.inviteMemberMultiCtrl.value
      };

      this.activityService.inviteMembers(Invitations).subscribe(() => {
        this.viewEvent = false;
      });
    }

    unassignMember(activityId: string, slotId: string) {
      this.selectedActivity = undefined;
      this.activityService.unassignMember(activityId, slotId).subscribe(res => {
        console.log(res);
        const calendarApi = this.calendarComponent.getApi();
        this.getActivities();
        calendarApi.destroy();
        calendarApi.render();
        this.viewEvent = false;
      });


    }

    getMembers() {
      this.memberService.getData().subscribe((members: Member[]) => {
        if (members) {
          this.members = [];
          this.members = members;
            // load the initial Member list
          this.filteredMembers.next(this.members.slice());
        }
      });
    }
    ngOnDestroy() {
      this.onDestroy.next();
      this.onDestroy.complete();
    }

  /**
   * Sets the initial value after the filteredMembers are loaded initially
   */
  protected setInitialValue() {
    this.filteredMembers
      .pipe(take(1), takeUntil(this.onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredMembers are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: Member, b: Member) => a && b && a.id === b.id;
      });
  }
  protected filterMembers() {
    if (!this.members) {
      return;
    }
    // get the search keyword
    let search = this.memberFilterCtrl.value;
    if (!search) {
      this.filteredMembers.next(this.members.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the Members
    this.filteredMembers.next(
      this.members.filter(
        member => member.firstName.toLowerCase().indexOf(search) > -1 ||  member.lastName.toLowerCase().indexOf(search) > -1)
    );

  }
  }

