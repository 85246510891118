<form [formGroup] = "certificateForm" class="form">
<h1 mat-dialog-title>Certificaat toevoegen</h1>
<div mat-dialog-content>
        <mat-form-field class="certificate commonField">
            <mat-label>Selecteer Certificaat</mat-label>
            <mat-select formControlName = "certificate">
              <mat-option *ngFor="let certificate of data.certificates" [value]="certificate" aria-selected="true">
                {{certificate.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="userStreet commonField">
            <mat-label>Vervaldatum</mat-label>
            <input matInput [matDatepicker] = "picker"  formControlName="expirationDate" placeholder = "Vervaldatum">
            <mat-datepicker-toggle matSuffix [for] = "picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
</div>
<div mat-dialog-actions>
  <button  [disabled]="!certificateForm.valid" class="button btn btn-primary"  mat-button [mat-dialog-close]="certificateForm.value" cdkFocusInitial><i class="far fa-save icons-mar"></i> Opslaan</button>
  <button mat-button (click)="onNoClick()">Annuleren</button>
</div>
</form>
