import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort} from '@angular/material/sort';
import { forkJoin } from 'rxjs';
import { AlertDialogComponent } from 'src/app/Common/alert-dialog-component/alert-dialog-component';
import { Task } from 'src/app/models/taken/task.model';
import { TaskCategory } from 'src/app/models/taken/taskcategory.model';
import { TakenService } from 'src/app/service/taken.service';
@Component({
	selector: 'app-taken',
	templateUrl: './taken.component.html',
	styleUrls: ['./taken.component.css']
})
export class TakenComponent implements OnInit, AfterContentChecked {

  	details: any;
  	selectedRow: number;
  	openSideDetails = false;
  	displayedColumns: string[] = [ 'name', 'category', 'required', 'defaultTaskValue'];
	dataSource = new MatTableDataSource<Task>();
	tasks: Array<Task>;
	taskForm: FormGroup;
	task: Task;
	type: String;
	length: number;
	taskCategories: Array<TaskCategory>;
	selectedTaskCategory: string;
	organisationId: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort)   sorter: MatSort;

	constructor(private changeDetector: ChangeDetectorRef, private takenservice: TakenService,
				        private formBuilder: FormBuilder, private dialog: MatDialog)
	{

	}

	ngOnInit() {
		this.getTasks();
		this.getTaskCategories();
		this.ctreateTaskForm();

	}

	ctreateTaskForm() {
		this.taskForm =  this.formBuilder.group({
			id : new FormControl(),
			name : new FormControl(),
			minutes : new FormControl(),
			defaultTaskValue : new FormControl(),
			taskCategory : new FormControl(this.selectedTaskCategory),
			shortDescription : new FormControl(),
			description : new FormControl()
		});
	}

	addTask() {
		this.taskForm.reset();
		this.type = 'Add';
		this.details = [];
		this.task = new Task();
		this.details.push(this.task);
		this.openSideDetails = true;
  		this.changeDetector.detectChanges();
	}
	getTaskCategories() {
		this.takenservice.getTaskCategories().subscribe((resp: TaskCategory[]) => {
			if (resp) {
					this.taskCategories = resp;
				}
		});
	}

	/**
	 * Submit function
	 * Assign values to Task Model from filtered details
	 * Assign updated values Task Model
	 */
	onSubmit(): void {
		if (this.type === 'Add') {
			const _task = {
				organisationId : localStorage.getItem("OrganisationId"),
				name : this.taskForm.value.name,
				shortDescription : this.taskForm.value.shortDescription,
				description : this.taskForm.value.description,
				minutes : +(this.taskForm.value.minutes === null ? 0 : this.taskForm.value.minutes),
				defaultTaskValue : +this.taskForm.value.defaultTaskValue,
				category : this.taskCategories.filter(x => x.id ===  this.taskForm.value.taskCategory)[0]
			};
			this.takenservice.addTask(_task).subscribe((resp: Task) => {
				if (resp !== null) {
					this.getTasks();
				}
				this.CloseUserDetails();
			});
			this.changeDetector.detectChanges();
		} else {
			const id = this.taskForm.value.id;
			const _task = {
				id : this.taskForm.value.id,
				name : this.taskForm.value.name,
				shortDescription : this.taskForm.value.shortDescription,
				description : this.taskForm.value.description,
				minutes : + this.taskForm.value.minutes,
				defaultTaskValue : +this.taskForm.value.defaultTaskValue

			};
			const takenService$ = this.takenservice.updateTask(id, _task);
			const taskCategoryId = this.taskForm.value.taskCategory;
			const taskCategory$ = this.takenservice.updateTaskCategory(id, taskCategoryId);
			forkJoin([takenService$, taskCategory$]).subscribe(resp => {
				if (resp) {
					this.dataSource.data = [];
					this.dataSource.data = this.dataSource.data;
					this.getTasks();
					this.CloseUserDetails();
				}
			});
		}
	}

	/**
	 * Call Task Service to retrive list of tasks.
	 */
	getTasks() {
		this.takenservice.getData().subscribe((resp: Task[]) => {
			if (resp) {
				this.tasks = resp;
				this.dataSource.data = this.tasks;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sorter;
				this.changeDetector.detectChanges();
			}
			});
	}

	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	/**
	 *
	 * @param {GUID} id   Task Id
	 * @param {number} number : Selected Row Index
	 */
	getDetails(id, number) {
		this.type = 'Edit';
		this.details = this.tasks.filter(x => x.id == id);
		if (this.details.length > 0) {
			this.taskForm.patchValue(this.details[0]);
			this.taskForm.controls.taskCategory.setValue(this.details[0].category.id) ;
			this.selectedTaskCategory = this.details[0].category.id;
			this.selectedRow = number;
			this.openSideDetails = true;
		}
		this.changeDetector.detectChanges();
	}

	CloseUserDetails() {
		this.openSideDetails = false;
		this.selectedRow = -1;
		this.changeDetector.detectChanges();
	}

	ngAfterContentChecked() {
		this.changeDetector.detectChanges();
	}
	deleteTask(id: string) {
		this.CloseUserDetails();
		this.takenservice.deleteTask(id).subscribe((resp => {

			this.getTasks();
			// let index = this.dataSource.data.findIndex(x => x.id == id);
			// this.dataSource.data = this.dataSource.data.splice(index, 1);
			// this.details = this.tasks.filter(x => x.id != id);
		}), (error: any) => {
			console.log('error: ', error);
			this.dialog.open(AlertDialogComponent, {
				data: {
					message: 'Er is een probleem voorgekomen tijdens het verwijderen van deze taak, probeer het later nog eens.',
					buttonText: {
						cancel: 'Oke'
					}
				},
			});
		});
		this.CloseUserDetails();
	}
}
