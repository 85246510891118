import { Component, OnInit, AfterContentChecked, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Certificate } from 'src/app/models/leden/certification.model';
import { CertificateService } from 'src/app/service/Leden/certificate.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.css']
})
export class CertificatesComponent implements OnInit, AfterContentChecked {
  selectedRow: number;
  openSideDetails = false;
  displayedColumns: string[] = ['name'];
  dataSource = new MatTableDataSource<Certificate>();
  certificates: Array<Certificate>;
  certificateForm: FormGroup;
  type: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort)   sorter: MatSort;

  constructor(private changeDetector: ChangeDetectorRef, private _cerfService: CertificateService, private formBuilder: FormBuilder) {

  }

  ngOnInit() {
    this.getCertificates();
  }
  getCertificates() {
    this._cerfService.findAll().subscribe((resp: Array<Certificate>) => {
      if (resp) {
        this.dataSource.data = resp;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sorter;
        this.certificates = resp;
      }
    });
  }
  getDetails(id, number) {
    let _Certificate = 	this.certificates.filter(x => x.id == id);
    if (_Certificate.length > 0) {
      this.createCertificateForm();
      this.certificateForm.patchValue(_Certificate[0]);
      this.selectedRow = number;
      this.openSideDetails = true;
      this.type = 'Edit';
    }
    this.changeDetector.detectChanges();
  }

  createCertificateForm() {
    this.certificateForm =  this.formBuilder.group({
      id: new FormControl(),
			name : new FormControl(null, Validators.required),
		});
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  CloseUserDetails() {
    this.openSideDetails = false;
    this.selectedRow = -1;
    this.changeDetector.detectChanges();
}
addCertificate() {
  this.type = 'Add';
  this.createCertificateForm();
  this.openSideDetails = true;
}
onSubmit(): void {
  if (this.certificateForm.valid) {
  if (this.type === 'Edit') {
    const id = this.certificateForm.controls.id.value;
    this._cerfService.update(id, this.certificateForm.value).subscribe((resp: Certificate) => {
     Object.assign(this.certificates.find(x => x.id === resp.id), resp);
     this.dataSource.data = this.certificates;
     this.CloseUserDetails();
    });
  }
  if (this.type === 'Add') {
    const data = {
      name : this.certificateForm.controls.name.value
    };
    this._cerfService.customAdd(data).subscribe((resp: Certificate) => {
      if (resp) {
        this.certificates.push(resp);
        this.dataSource.data = this.certificates;
        this.CloseUserDetails();
      }
    });
  }
}
}
ngAfterContentChecked() {
  this.changeDetector.detectChanges();
}
ngAfterViewInit() {
  this.dataSource.paginator = this.paginator;
}

deleteCertificate() {
  const id = this.certificateForm.controls.id.value;
  this._cerfService.delete(id).subscribe((resp: Certificate) => {

    this.certificates = this.certificates.filter(x => x.id !== id);
    this.dataSource.data = this.certificates;
    this.CloseUserDetails();
  });
}
}
