<app-main-nav>

  <h2 class="page-title" >Taken
  <i class="fas fa-plus-circle bg-blue" (click)="addTask()"></i>

  </h2>
  <div class="laden commonFlex">
    <div class="mat-elevation-z8" [className]="openSideDetails == true ? 'half-width' : 'full-width'">
      <div class="filtration">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        <mat-paginator   [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
    </div>
    <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Titel </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
      <!-- Lastname Column -->
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef> Categorie </th>
        <td mat-cell *matCellDef="let element"> {{element.category.name}} </td>
      </ng-container>


      <!-- Saldo Column -->
      <ng-container matColumnDef="defaultTaskValue">
        <th mat-header-cell *matHeaderCellDef > Punten </th>
        <td mat-cell *matCellDef="let element"> {{element.defaultTaskValue}} </td>
      </ng-container>

      <!-- Required Column -->
      <ng-container matColumnDef="required">
        <th mat-header-cell *matHeaderCellDef > Vereist </th>
        <td mat-cell *matCellDef="let element"> {{element.required}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row (click)="getDetails(row.id, i)" [class.active]="i == selectedRow"  *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
    </table>
  </div>

  <div *ngIf="openSideDetails" [className]="openSideDetails == true ? 'half-width' : 'full-width'">
    <div class="userDetail" *ngFor="let task of details">
      <button (click)="CloseUserDetails()" class="close">Sluiten</button>
      <div class="userName commonFlex">
        <h2> {{task.name}} </h2>
        <div class="userScore"><i class="fas fa-star icons-mar"></i> <strong>{{task.defaultTaskValue}}</strong> </div>
      </div>
      <form [formGroup]="taskForm"  class="form" (ngSubmit)="onSubmit()">
        <input matInput   formControlName="id"  value="{{task.id}}" hidden = "true">
        <div class="userUpperDetail commonFlex">
          <div class="user_leftDetail">
            <mat-form-field class="user_name commonField">
              <input matInput placeholder="Naam" formControlName="name"   value="{{task.name}}">
            </mat-form-field>
            <mat-form-field class="userStreet commonField">
              <textarea matInput
              placeholder="Omschrijving" formControlName="description" value="{{task.description}}"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="lowerDetails">
        <mat-form-field class="width-5 commonField">
          <mat-label>Categorie</mat-label>
          <mat-select (value)="taskCategories[0].id"  formControlName="taskCategory" >
            <mat-option *ngFor="let _item of taskCategories" value="{{_item.id}}" aria-selected="true">
              {{_item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <div class="lowerDetails">
        <mat-form-field class="width-5 commonField">
          <mat-label>Benodigd certificaat</mat-label>
          <input matInput placeholder="Certificaat" value="{{task.required}}">
        </mat-form-field>
      </div> -->
    <mat-form-field class="userStreet commonField">
      <input matInput placeholder="Waarde in punten" value="{{task.defaultTaskValue}}" formControlName="defaultTaskValue">
    </mat-form-field>
    <button type="submit" class="btn btn-primary"><i class="material-icons icons-mar">save</i> Opslaan</button>
    <div class="lowerdetails">
      <button (click)="deleteTask(task.id)" type="button" class="btn btn-danger"><i class="material-icons icons-mar">delete</i> Verwijderen</button>
    </div>
  </form>

</div>
</div>
</div>


</app-main-nav>
