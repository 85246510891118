<app-main-nav>
<mat-tab-group mat-align-tabs="start">
    <mat-tab label="Home">
      <div class="actionButtons">
        <h2 class="page-title" (click)="addMember()">Leden <i class="fas fa-plus-circle bg-blue"></i></h2>
        <h2 class="page-title" (click)="importMembers()">Importeer <i class="fas fa-upload bg-blue"></i>
        </h2>
      </div>
      <div class="laden commonFlex">
        <div class="mat-elevation-z8" [className]="openSideDetails == true ? 'half-width' : 'full-width'">
          <div class="filtration">
           <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
          <mat-paginator  [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>


         </div>
         <table mat-table [dataSource]="dataSource" matSort matSortActive="firstName" matSortDirection="asc">

          <!-- Name Column -->
          <ng-container matColumnDef="firstName">

            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by first name">Voornaam</th>
            <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
          </ng-container>
          <!-- Lastname Column -->
          <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by lirst name">Achternaam</th>
            <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
          </ng-container>


          <!-- Saldo Column -->
          <ng-container matColumnDef="saldo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by saldo">Saldo</th>
            <td mat-cell *matCellDef="let element"> {{element.saldo}} </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="symbol">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by subject">Betreft</th>
            <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row (click)="getDetails(row.id, i)" [class.active]="i == selectedRow"  *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
        </table>
      </div>

      <div *ngIf="openSideDetails" [className]="openSideDetails == true ? 'half-width' : 'full-width'">
        <div class="userDetail" *ngFor="let user of details">
          <button (click)="CloseUserDetails()" class="close">Sluiten</button>
          <div class="userName commonFlex">
            <h2> {{user.firstName}} </h2>
            <div class="userScore"><i class="fas fa-star icons-mar"></i> <strong>{{exemptionTotal}}</strong> 5000 </div>
          </div>
          <form class="form"  [formGroup]="memberForm" class="form"  (ngSubmit)="onSubmit()">
            <div class="lowerDetails commonFlex">
              <div class="user_leftDetail">
                <mat-form-field class="email commonField">
                  <input matInput placeholder="Email"  formControlName="emailAddress" type="email">
                  <div *ngIf="emailAddress.invalid && (emailAddress.dirty || emailAddress.touched)"
                    class="alert alert-danger">
                      <div *ngIf="emailAddress.errors.required" class="validation-error">
                        Email is required.
                      </div>
                  </div>
                </mat-form-field>
                <mat-form-field class="user_name commonField">
                  <input matInput placeholder="Voornaam"  formControlName="firstName" value="{{user.firstName}}">
                  <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
                  class="alert alert-danger">
                    <div *ngIf="firstName.errors.required" class="validation-error">
                      Voornaam is required.
                    </div>
                </div>
                </mat-form-field>
                <mat-form-field class="user_name commonField">
                  <input matInput placeholder="Achternaam"  formControlName="lastName" value="{{user.lastName}}">
                  <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
                  class="alert alert-danger">
                    <div *ngIf="lastName.errors.required" class="validation-error">
                      Achternaam is required.
                    </div>
                </div>
                </mat-form-field>
                <mat-form-field class="user_name commonField">
                  <mat-label>Geslacht</mat-label>
                  <mat-select formControlName="gender">
                    <mat-option *ngFor="let gender of genders" [value]="gender.value">
                      {{gender.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="lowerDetails">
              <div formArrayName="addresses" class="width-7 commonField">
                <div *ngFor="let address of getControls(); let i = index" [formGroupName]="i" >
                  <div *ngIf="user.addresses[i]">
                    <mat-form-field class="width-6 commonField">
                      <input matInput placeholder="Street" formControlName="streetName" value="{{user.addresses[i].streetName}}">
                    </mat-form-field>
                    <mat-form-field class="width-2 commonField">
                      <input matInput placeholder="Number" formControlName="houseNumber" value="{{user.addresses[i].houseNumber}}">
                    </mat-form-field>
                    <mat-form-field class="width-2 commonField">
                      <input matInput placeholder="Toev." formControlName="houseNumberAddition" value="{{user.addresses[i].houseNumberAddition}}">
                    </mat-form-field>
                    <mat-form-field class="width-5 commonField">
                      <input matInput placeholder="Postcode" formControlName="postalCode" value="{{user.addresses[i].postalCode}}">
                    </mat-form-field>
                    <mat-form-field class="width-5 commonField">
                      <input matInput placeholder="Plaatsnaam" formControlName="place" value="{{user.addresses[i].place}}">
                    </mat-form-field>
                  </div>
                  <div *ngIf="!user.addresses[i]">
                    <mat-form-field class="width-6 commonField">
                      <input matInput placeholder="Street" formControlName="streetName">
                    </mat-form-field>
                    <mat-form-field class="width-2 commonField">
                      <input matInput placeholder="Number" formControlName="houseNumber">
                    </mat-form-field>
                    <mat-form-field class="width-2 commonField">
                      <input matInput placeholder="Toev." formControlName="houseNumberAddition">
                    </mat-form-field>
                    <mat-form-field class="width-5 commonField">
                      <input matInput placeholder="Postcode" formControlName="postalCode">
                    </mat-form-field>
                    <mat-form-field class="width-5 commonField">
                      <input matInput placeholder="Plaatsnaam" formControlName="place">
                    </mat-form-field>
                  </div>
                </div>
                </div>
              <!-- <mat-form-field class="width-5 commonField">
                <mat-label>Betreft</mat-label>
                <mat-select matNativeControl required>
                  <mat-option value="male">Ouder Van</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="width-5 commonField">
                <input matInput placeholder="Lid" value="Kevin, Branco en lotte">
              </mat-form-field> -->
              <div *ngIf="type === 'EDIT'" class="certification">
                <h4>Certificaten <i class="fas fa-plus-circle bg-blue" (click)="openCertificateDialog()"></i></h4>
                <ul>
                  <li>
                    <span class="width-6">Titel</span>
                    <span class="width-2">Geldig Van</span>
                  </li>
                  <li  *ngFor="let certificate of user.certifications">
                    <p label="Titel" class="width-6">{{certificate.certificate.name}}</p>
                    <p label="Geldig Van" class="width-4">{{certificate.expirationDate  | date: 'dd/MM/yyyy'}}</p>
                  </li>

                </ul>
                <!-- <h4>Vrijstellingen <i class="fas fa-plus-circle bg-blue"></i></h4>
                <ul class="second_List">
                  <li>
                    <span class="width-8">Type</span>
                    <span class="width-2">Punten</span>
                  </li>
                  <li *ngFor="let exemption of exemptions">
                    <p class="width-8">{{exemption.name}}</p>
                    <p class="width-2">- {{exemption.points}}</p>
                  </li>
                  <li class="no-border">
                    <strong class="width-10">- {{exemptionTotal}}</strong>
                  </li>
                </ul> -->
              </div>
            </div>
            <button type="submit" class="btn btn-primary"><i class="far fa-save icons-mar"></i> Opslaan</button>
            <div class="lowerdetails">
              <button (click)="deleteMember(member.id)" type="button" class="btn btn-danger"><i class="material-icons icons-mar"  >delete</i> Verwijderen</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    </mat-tab>
    <mat-tab label="Certificaten"> <ng-template matTabContent>
      <app-certificates></app-certificates>
    </ng-template></mat-tab>
</mat-tab-group>
</app-main-nav>
